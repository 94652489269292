<template>
  <div class="sign_in wrapper">
    <header class="logo grid-content">
      <img class="logo-img" src="@/assets/images/logo_background.png" alt="OkeyDocs Logo">
    </header>
    <section class="grid-content">
      <div class="contenedor">
        <form @submit.prevent="getAccess()">
          <div class="form-group">
            <label for="exampleInputEmail1">{{ $t('sessionEmail') }}</label>
            <input
              v-model="user.email"
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              :placeholder="$t('sessionEmailPlaceholder')"
            >
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">{{ $t('sessionPassword') }}</label>
            <input
              v-model="user.password"             
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              :placeholder="$t('sessionPasswordPlaceholder')">

          </div>
          <div class="session-button">
            <a class="forgot--btn" href="/#/forgot-password">{{ $t('sessionForgotPassword') }}</a>
            <a class="forgot--btn" href="/#/delete-account">{{ $t('sessionDeleteAccount') }}</a>                              
            <button type="submit" class="btn signup--btn">{{ $t('sessionSignInButton') }}</button>
          </div>
        </form>
      </div>
      <div>    <button class="button-flag" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)"> <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}} </button></div>
    </section>
  </div>
</template>

<script>
  import i18n from '@/plugins/i18n';
  import axios from 'axios';


  export default {
    data() {
      return {
        image:"en",
        user:{
          email:"",
          password:""
        },
        languages: [{ 
                      flag: 'gb', 
                      language: 'en', 
                      title: 'English' 
                    },
                    { flag: 'es', 
                      language: 'es', 
                      title: 'Español' },
                    { flag: 'de',
                      language: 'de',
                      title: 'Deutsch'}]
      };
    },
    methods: {
      getAccess () {
        console.log("Comprobación de acceso, Actualizada la pagina");
        console.log("El usuario es el siguiente: ",this.user);
        
        axios.post('https://okeydocs.es:3000/okey/api/user/auth',this.user)
        .then(response => {
          localStorage.setItem("session", JSON.stringify(response.data));
          localStorage.setItem("user_email", this.user.email);
          localStorage.setItem("is_logged",true);

          this.$toastr.s(i18n.t("correctLogin",this.language));
          
          this.$router.replace('/dashboard/view-files');
        })
        .catch(() => {
          this.$toastr.e(i18n.t("uncorrectLogin",this.language));
        });    
      },
      changeLocale(locale) {        
        i18n.locale = locale;
        this.image = locale;
        localStorage.setItem("language",locale);
      },
      forgotPassword(){
        this.$router.replace('/forgot-password');
      }
    },
    created(){
      localStorage.setItem("language","en");
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenedor {
  padding: 10% 30px;
  border: 2px solid rgb(160, 206, 98);
  border-radius: 20px;
}

.logo {
  padding: 40px;
}

.logo-img {
  max-width: 300px;
}

h1 {
  color: #7ab648;
}

.forgot--btn {
  float: left;
  text-decoration: none;
  color: rgb(160, 206, 98)
}
.forgot--btn:hover {
  color: white;
}


a.signup--btn  {
  color:white;
}

a.btn .signup--btn:hover {
  color: white;
}

.btn {
  background-color: black;
  color: rgb(160, 206, 98);
  border: 1px solid rgb(160, 206, 98);
}

.btn:hover {
  background-color: rgb(160, 206, 98);
  color: black;
}

.wrapper {
  /* padding: 50px auto; */
  display: grid;
  grid-template-columns: 1fr minmax(200px, 700px) 1fr;
}

input:-webkit-autofill,
input{
  background-color: black;
  border: 1px solid rgb(160, 206, 98);
  text-align: center;
}


input:-webkit-autofill,
input:focus{
  background-color: black;
  border: 1px solid rgb(160, 206, 98);
  text-align: center;
}


@media only screen and (max-width: 430px) {
  .wrapper {
    /* padding: 50px auto; */
    display: grid;
    grid-template-columns: 1fr minmax(200px, 300px) 1fr;
  }
}

.grid-content {
  grid-column: 2/3;
}

.session-button {
  display: flex;
  justify-content: space-between;
}

.footer{
  margin-top: 10%;
}
.footer-img{
  width: 300px;
  height: 60px;
}

.button-flag {    
    padding: 15px;
    background-color: black;
    color: rgb(160, 206, 98);
    border: 1px solid rgb(160, 206, 98);
    border-radius: 10px;   
    font-size: 18px;    
    margin: 15px;
}
.button-flag:hover{
    background-color: rgb(160, 206, 98);
    color: black;
}
</style>
