<template>
  <div class="grid">
    <div class="grid-content">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th>{{$t('tableHeaderFiles')}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(datos) in pagination[page]" :key="datos.id">
            <th scope="row">{{datos.id}}</th>
            <td><a v-bind:href=datos.url>{{datos.name}}</a></td>
            <td>
              <a id ="img-delete" href="#" @click="deleteKey(datos)">  
                <img src="@/assets/images/waste-24.png">
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid-content all-pagination">
      <nav class="bottom--pagination">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <button class="page-link" :disabled="disabledBack" @click="back()">{{$t('tableUsersBack')}}</button>
        </li>
        <li class="page-items" v-for="(file, index) in pagination" :key="file.id">
          <a class="page-link page-link--items"  :class="{ page_item_selected: (index + 1 === page + 1) }" @click="changePage(index)" href="#">{{index + 1}}</a>
        </li>
        <li v-if="empty" class="page-item">
          <a class="page-link" href="#">1</a>
        </li>
        <li class="page-item">
          <button type="button" :disabled="disabledNext" @click="next()" class="page-link">{{$t('tableUsersNext')}}</button>
        </li>
      </ul>
    </nav>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import axios from 'axios';

export default {
  data() {
    return {
      ficheros: [],
      pagination: [],
      page: 0,
      hidePrevious: false,
      hideNext: false,
      empty: true,
      disabledNext: false,
      disabledBack: false
    };
  },
  methods: {
    loadDatos() {
      var userEmail = localStorage.getItem("user_email");
      axios.get("https://okeydocs.es:3000/okey/api/user/" + userEmail + "/files")
        .then(response => {
          let fichero = {}
              
          response.data.forEach((dato, index) => {
            fichero.id = index + 1;
            fichero.name = this.getFileName(dato);
            fichero.url = dato;
            this.ficheros.push(fichero);
            fichero ={}
          });
        })
        .then(() => {
          this.pagination = this.getPagination(this.ficheros, 12);

          this.pagination.length == 0
            ? (this.empty = true)
            : (this.empty = false);

          if (this.pagination.length <= 1) {
            this.disabledNext = true;
            this.disabledBack = true;
          } else {
            this.disabledNext = false;
            this.disabledBack = false;
          }

          this.page == 0 ? (this.disabledBack = true) : null;
        })
        .catch(() => {
          this.$router.replace("/");
        });
    },
    getPagination(array, number) {
      let pagination = [];
      let userPerPage = [];

      array.forEach(users => {
        userPerPage.push(users);
        if (userPerPage.length >= number) {
          pagination.push(userPerPage);
          userPerPage = [];
        }
      });
      if (userPerPage.length > 0) {
        pagination.push(userPerPage);
      }
      return pagination;
    },
    getFileName(url) {
      return url.substring(url.lastIndexOf('/')+1);
    },
    getTime(dateTime) {
      var fecha = new Date(dateTime);
      return `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()} ${((fecha.getHours()) % 24 < 10 ? "0" : "") +
        ((fecha.getHours()) % 24)}:${((fecha.getMinutes()) % 60 < 10
        ? "0"
        : "") +
        ((fecha.getMinutes()) % 60)}`;
    },
    deleteKey(user) {
      var language = localStorage.getItem("language");
      var userEmail = localStorage.getItem("user_email");
      console.log(user , language);
      var popup={};

      popup.title=i18n.t("deletingFileTitle", language);
      popup.text=i18n.t("deletingFileText", language);
      popup.confirmButton=i18n.t("deletingFileConfirmButton", language);
      popup.cancelButton=i18n.t("logoutTextCancelOption", language);
      popup.confirm=i18n.t("deletingFileOk", language);

      this.$swal
        .fire({
          position: "top-center",
          title: popup.title,
          text: popup.text,
          showCancelButton: true,
          confirmButtonColor: "#6E757C",
          background: "#A0CE62",
          border: "5px solid #A0CE62",
          cancelButtonColor: "#000",
          cancelButtonText: popup.cancelButton,
          confirmButtonText: popup.confirmButton
        })
        .then(() => {
          axios.delete("https://okeydocs.es:3000/okey/api/user/" + userEmail + "/file/" + user.name)
              .then(() => {
                this.ficheros = this.ficheros.filter(
                  fichero => fichero.name != user.name
                );
                this.pagination = this.getPagination(this.ficheros, 12);
                if (this.pagination.length == 0) {
                  this.empty = true;
                } else {
                  this.empty = false;
                }
                this.$swal.fire({
                  position: "top-end",
                  type: "success",
                  title: popup.confirm,
                  background: "#A0CE62",
                  showConfirmButton: false,
                  timer: 1500
                });
              })
              .catch((err) => {
                console.log(err);
                this.$router.replace("/");
              });
        });

      if (this.pagination.length <= 1) {
        this.disabledNext = true;
        this.page = 0;
      } else {
        this.disabledNext = false;
      }
    },
    getUserByPage() {
      return this.pagination[0];
    },
    changePage(numberOfPage) {
      this.page = numberOfPage;
      if (this.page == this.pagination.length - 1) {
        this.disabledNext = true;
      } else {
        this.disabledNext = false;
      }
      if (this.page == 0) {
        this.disabledBack = true;
      } else {
        this.disabledBack = false;
      }
    },
    next() {
      this.page++;
      if (this.page == this.pagination.length - 1) {
        // console.log("Verificando si se cumple");
        this.disabledNext = true;
      } else {
        this.disabledNext = false;
      }
      this.disabledBack = false;
    },
    back() {
      this.page--;
      if (this.page <= 0) {
        this.disabledBack = true;
      } else {
        this.disabledBack = false;
      }
      this.disabledNext = false;
    },
    setLanguage(){
      if(localStorage.getItem('language')){
        i18n.locale = localStorage.getItem('language'); 
      }else{
        console.log("No hay lenguaje");
      }
    }
  },
  created() {
    this.loadDatos();
    this.setLanguage();
  }
};
</script>

<style scoped >
.grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1170px 1fr;
}

li {
  text-align: left;
}

td,
th,
a {
  color: rgb(160, 206, 98);
  text-decoration: none;
}
.grid-content {
  grid-column: 2/3;
  height: 740px;
}
.all-pagination{
  height: 38px;
}

.img-delete{
  width: 20px;
  height: 20px;
}

button{
  border-color:  rgb(160, 206, 98);
  background-color: black;
  color: rgb(160, 206, 98);
}
.page_item_selected {
  background-color: black !important;
  color: rgb(160, 206, 98) !important;
}
button:hover {
  background-color: rgb(160, 206, 98);
  color: black;
}

.page-link--items {
  background-color: rgb(160, 206, 98);
  color: black;
}

.page-link--items:hover {
  background-color: rgb(160, 206, 98);
  color: white;
}

.bottom--pagination {
  position: static;
  top: 90vh;
  left: calc(50% - 100px);
}

@media(max-width:1160px){
  .grid{
    grid-template-columns: 1fr 99% 1fr;
    font-size: 75%;
  }
  .delete-btn {
    border: none;
    background-color: unset;
    padding: 10px;
    /*background-image: url("@/assets/delete_white.png");*/
    background-size: cover;
  }
}

@media(max-width:768px){
  .grid{
    grid-template-columns: 1fr 99% 1fr;
    font-size: 45%;
  }
  .delete-btn {
    border: none;
    background-color: unset;
    padding: 8px;
    /*background-image: url("@/assets/delete_white.png");*/
    background-size: cover;
  }
}

@media(max-width:480px){
  .grid{
    grid-template-columns: 1fr 99% 1fr;
    font-size: 25%;
  }
  .delete-btn {
    border: none;
    background-color: unset;
    padding: 4px;
    /*background-image: url("@/assets/delete_white.png");*/
    background-size: cover;
  }
}

</style>
