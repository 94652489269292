<template>
  <div class="sign_in wrapper">
    <section class="grid-content">
      <div class="contenedor">
        <form @submit.prevent="sendEmail()">
          <div class="form-group">
            <label for="exampleInputEmail1">
               <p>{{ $t('passwordForgotEmail') }}</p>
               <p>{{ $t('passwordForgotEmail2') }}</p>
            </label>
            <input
              v-model="email"
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              :placeholder="$t('sessionEmailPlaceholder')"
            />
          </div>
          <div class="forgot-button">
            <a class="forgot--btn" href="/#/">{{ $t('backButton') }}</a>    
            <button type="submit" class="btn signup--btn">{{ $t('passwordForgotSendButton') }}</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
  import i18n from '@/plugins/i18n';
  import axios from 'axios';

  export default {
    data() {
      return {
        language : localStorage.setItem("language"),
        image:"en",
        email:"",
        languages: [{ 
                      flag: 'us', 
                      language: 'en', 
                      title: 'English' 
                    },
                    { flag: 'es', 
                      language: 'es', 
                      title: 'Español' },
                    { flag: 'de',
                      language: 'de',
                      title: 'Deutsch'}]
      };
    },
    methods: {
      sendEmail () {
        //Si no hay email
        if(!this.email){
            this.$toastr.e(i18n.t("noEmailForgotPassword", this.language));
        }else{
            console.log(`Enviamos email a la dirección facilitada ${this.email}`);
            if(this.language){
              axios.post('https://okeydocs.es:3000/okey/api/user/reset-password',{email:this.email})
              .then(response => {
                console.log(response.data);
                if(response.status==200){
                    this.$toastr.s(i18n.t("sendEmailForgotPassword", this.language));
                    this.$router.replace('/');
                }else{
                  this.$toastr.e(i18n.t("noSendEmailForgotPassword", this.language));
                }              
              })
              .catch(() => {
                this.$toastr.e(i18n.t("noSendEmailForgotPassword", this.language));
              });
            }else{
              console.log("No hay lenguaje seleccionado");
            }  
        }       
      }
    },
    created(){
      this.language=localStorage.getItem("language");
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.contenedor {
  padding: 5% 30px;
  border: 2px solid rgb(160, 206, 98);
  border-radius: 20px;
}

.logo {
  margin-right: 38px;
  padding: 40px;
}

.logo-img {
  max-width: 200px;
}

.logo-text {
  max-width: 150px;
}

h1 {
  color: #7ab648;
}

.forgot--btn {
  float: left;
  text-decoration: none;
  color: rgb(160, 206, 98)
}
.forgot--btn:hover {
  color: white;
}


a.signup--btn  {
  color:white;
}

.btn {
  background-color: black;
  color: rgb(160, 206, 98);
  border: 1px solid rgb(160, 206, 98);
}
.btn:hover {
  background-color: rgb(160, 206, 98);
  color: black;
}

input:-webkit-autofill,
input{
  background-color: black;
  border: 1px solid rgb(160, 206, 98);
  text-align: center;
}


input:-webkit-autofill,
input:focus{
  background-color: black;
  border: 1px solid rgb(160, 206, 98);
  text-align: center;
}

.wrapper {
  margin-top: 10%;
  display: grid;
  grid-template-columns: 1fr minmax(200px, 700px) 1fr;
}

@media only screen and (max-width: 430px) {
  .wrapper {
    /* padding: 50px auto; */
    display: grid;
    grid-template-columns: 1fr minmax(200px, 300px) 1fr;
  }
}

.grid-content {
  grid-column: 2/3;
}

.forgot-button {
  display: flex;
  justify-content: space-between;
}

.footer{
  margin-top: 10%;
}
.footer-img{
  width: 300px;
  height: 60px;
}
</style>