import VueRouter from "vue-router";
import SessionComponent from '@/components/SessionComponent'
import ForgotPass from '@/components/ForgotPass'
import DashboardComponent from '@/components/DashboardComponent'
import FilesComponent from '@/components/FilesComponent'
import DeleteAccount from '@/components/DeleteAccount'

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'SessionComponent',
      component: SessionComponent
    },
    {
      path: '/forgot-password',
      name: 'ForgotPass',
      component: ForgotPass
    },
    {
      path: '/delete-account',
      name: 'DeleteAccount',
      component: DeleteAccount
    },
    {
      path: '/dashboard',
      name: 'DashboardComponent',
      component: DashboardComponent,
      children: [
        {
          path: 'view-files',
          name: 'FilesComponent',
          component: FilesComponent
        }
      ]
    }
  ]
})
