<template>
  <div>
    <nav class="navbar navbar-expand-lg">
      <div>
        <a class="navbar-brand" href="#">
          <img src="@/assets/images/logo_background.png" alt style="max-width: 60px" />
        </a>
      </div>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/dashboard/view-files" class="nav-link nav-style" exact>{{$t('dashboardFiles')}}</router-link>
            <div class="marcador"></div>
          </li>
        </ul>
        <div class="navbar-brand--left">
          <div class="navbar-nav--dropdown">
            <b-dropdown :text="language.toUpperCase()" variant="success">
              <b-dropdown-item id ="navbar-nav--dropdown-button" class="button-flag"  v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)"> 
                <b-dropdown-item-button v-if="language!=entry.language"><flag :iso="entry.flag" v-bind:squared=false /> {{entry.language.toUpperCase()}}</b-dropdown-item-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="navbar-brand--logout">
            <a id ="img-logout" href="#" @click="logout()">  
              <img src="@/assets/images/exit-64.png">
            </a>
          </div>
        </div>
      </div>
    </nav>

    <router-view></router-view>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
    data(){
      return{
        refresh:0,
        language:localStorage.getItem('language'),
        languages: [{ 
                      flag: 'gb', 
                      language: 'en', 
                      title: 'English' 
                    },
                    { flag: 'es', 
                      language: 'es', 
                      title: 'Español' },
                    { flag: 'de',
                      language: 'de',
                      title: 'Deutsch'}]
      };
    },
    methods: {
        logout(){
          this.$swal
            .fire(
              {
                title: i18n.t("logoutTextTitle",this.language),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#6E757C",
                background: "#A0CE62",
                border: "5px solid #A0CE62",
                cancelButtonColor: "#000",
                cancelButtonText: i18n.t("logoutTextCancelOption",this.language),
                confirmButtonText: i18n.t("logoutTextOption",this.language)
              }
            )
            .then(result => {
              if (result.value) {
                    localStorage.clear();
                    this.$router.replace("/");
            }})
        },
        changeLocale(locale) {        
          i18n.locale = locale;
          this.language=locale;
          localStorage.setItem("language",locale);      
        } 
    }
}
</script>

<style>
#img-logout img{
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
}
.navbar{
  background-color: black;
}
.nav-link{
  color: rgb(160, 206, 98)
}

.nav-link:hover{
  color: white
}
.dropdown{
  margin-top: 15px;
}
.key-cart {
  display: flex;
}
.dropdown-item{
  background-color: black;
  color: rgb(160, 206, 98);
}

#key-counter {
  margin-right: 50px;
}
.nav-style{
  border-top: 5px solid rgb(160, 206, 98);
}
.nav-style:hover {
  border-top: 5px solid white;
  padding-bottom: 0;
}
.nav-style.router-link-active{
  border-top: 5px solid rgb(160, 206, 98);
}

#navbarNav {
  display: flex !important;
  justify-content: space-between;
  margin-left: 30%;
}

.navbar-nav {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
}
.navbar-brand--left{
  display:grid;
  grid-template-columns: repeat(2,50%);
  grid-gap: 20%;
  margin-right: 5%;
}
.navbar-nav--dropdown{
  grid-column: 0/1;
}

@media (max-width: 1024px) {
  .navbar-nav {
    margin-left: calc(50% - 100px);
    grid-gap: 20px;
  }
  .navbar-brand--left{
    margin-left:10%;
  }
  #navbarNav {
    position: absolute;
    margin-left: 20%;
  }
  #nav-key-item {
    width: 80px;
  }
}

@media (max-width: 480px) {
  .navbar-nav {
    margin-left: calc(50% - 80px);
    grid-gap: 10px;

  }
  #navbarNav {
    position: absolute;
    margin-left: 10%;
  }
  #nav-key-item {
    width: 80px;
  }
  .navbar-brand--left{
    margin-left:5%;
  }
}

</style>
