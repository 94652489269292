import Vue from 'vue'
import App from './App.vue'
import Router from '@/routes';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';

import Toastr from 'vue-toastr';
//Translator
import i18n from './plugins/i18n';
//Flag icons
import FlagIcon from 'vue-flag-icon';
import axios from 'axios';
import Donut from 'vue-css-donut-chart';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-css-donut-chart/dist/vcdonut.css';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueRouter)
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue) 
Vue.use(Toastr, { 
	defaultTimeout: 2000,
	// defaultProgressBar: false,
	// defaultProgressBarValue: 0,
	defaultType: "error",
	// defaultPosition: "toast-bottom-left",
	defaultCloseOnHover: false,
	// defaultStyle: { "background-color": "red" }
});
//Flag Icons
Vue.use(FlagIcon)
Vue.use(Donut)
Vue.use(axios);

Vue.config.productionTip = false

new Vue({
  el: "#app",
  router: Router,
  i18n,
  render: h => h(App),
})
