import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const messages = {  
                    'en': 
                    {
                        backButton: 'Back',
                        sessionEmail: 'Email address',
                        sessionEmailPlaceholder:'Enter email',
                        sessionPassword:'Password',
                        sessionPasswordPlaceholder:'Enter password',
                        sessionForgotPassword:'Forgot password?',
                        sessionDeleteAccount:'Delete Account',
                        sessionSignInButton: 'Sign in',
                        dashboardFiles: 'Files',
                        correctLogin:'Correct Login',
                        uncorrectLogin: 'Uncorrect Login',
                        logoutTextTitle:'Do you want to logout?',
                        logoutTextOption:'Yes',
                        logoutTextCancelOption:'Cancel',
                        passwordForgotEmail: 'An email will be sent to the address provided to reset the password',
                        deleteAccountTitle: 'An email will be sent to the address provided to delete your account',
                        passwordForgotEmail2: 'This email must be the same as the one used to log in',
                        passwordForgotSendButton: 'Send email',
                        tableHeaderFiles: 'File',
                        tableUsersBack: 'Back',
                        tableUsersNext: 'Next',
                        deletingFileTitle:'Are you sure?',
                        deletingFileText:'The file will be deleted',
                        deletingFileConfirmButton:'Delete',
                        deletingFileOk:'File deleted successfully',
                        noEmailForgotPassword: 'No email provided',
                        sendEmailForgotPassword: 'Email sent successfully',
                        noSendEmailForgotPassword: 'Email not sent, please try again later',
                    },    
                    'es': 
                    {
                        backButton: 'Atrás',
                        sessionEmail: 'Dirección email',
                        sessionEmailPlaceholder:'Introduzca email',
                        sessionPassword:'Contraseña',
                        sessionPasswordPlaceholder:'Introduzca la contraseña',
                        sessionForgotPassword:'¿Olvidaste la contraseña?',
                        sessionDeleteAccount:'Borrar Cuenta',
                        sessionSignInButton:'Iniciar Sesión',
                        dashboardFiles: 'Ficheros',
                        correctLogin:'Login Correcto',
                        uncorrectLogin: 'Login Incorrecto',
                        logoutTextTitle:'¿Quieres cerrar sesión?',
                        logoutTextOption:'Si',
                        logoutTextCancelOption:'Cancelar',
                        passwordForgotEmail: 'Se enviará un email a la dirección proporcionada para reestablecer la contraseña',
                        deleteAccountTitle: 'Se enviará un email a la dirección proporcionada para borrar tu cuenta',
                        passwordForgotEmail2: 'Este email tiene que ser el mismo que se usa para iniciar sesión',
                        passwordForgotSendButton: 'Enviar email',
                        tableHeaderFiles: 'Fichero',
                        tableUsersBack: 'Atrás',
                        tableUsersNext: 'Siguiente',
                        deletingFileTitle:'¿Estas segur@?',
                        deletingFileText:'Estas intentando borrar un archivo',
                        deletingFileConfirmButton:'Borrar',
                        deletingFileOk:'Archivo eliminado satisfactoriamente',
                        noEmailForgotPassword: 'No se ha proporcionado email',
                        sendEmailForgotPassword: 'Email enviado satisfactoriamente',
                        noSendEmailForgotPassword: 'Email no enviado, por favor intentelo más tarde',
                    },
                    'de':
                    {
                        backButton: 'Zurück',
                        sessionEmail: 'E-Mail-Adresse',
                        sessionEmailPlaceholder:'E-Mail eingeben',
                        sessionPassword:'Passwort',
                        sessionPasswordPlaceholder:'Geben Sie das Passwort ein',
                        sessionForgotPassword:'Passwort vergessen?',
                        sessionDeleteAccount:'Konto löschen',
                        sessionSignInButton:'Login',
                        dashboardFiles: 'Dateien',
                        correctLogin:'Korrektes Login',
                        uncorrectLogin: 'Falsches Login',
                        logoutTextTitle:'Möchten Sie sich abmelden?',
                        logoutTextOption:'Ja',
                        logoutTextCancelOption:'Abbrechen',
                        passwordForgotEmail: 'Eine E-Mail wird an die angegebene Adresse gesendet, um das Passwort zurückzusetzen',
                        deleteAccountTitle: 'Eine E-Mail wird an die angegebene Adresse gesendet, um Ihr Konto zu löschen',
                        passwordForgotEmail2: 'Diese E-Mail muss mit derjenigen übereinstimmen, mit der du dich angemeldet hast',
                        passwordForgotSendButton: 'E-Mail senden',
                        tableHeaderFiles: 'Datei',
                        tableUsersBack: 'Zurück',
                        tableUsersNext: 'Nächster',
                        deletingFileTitle:'Bist du sicher?',
                        deletingFileText:'Du versuchst eine Datei zu löschen',
                        deletingFileConfirmButton:'Löschen',
                        deletingFileOk:'Datei erfolgreich gelöscht',
                        noEmailForgotPassword: 'Keine E-Mail angegeben',
                        sendEmailForgotPassword: 'E-Mail erfolgreich gesendet',
                        noSendEmailForgotPassword: 'E-Mail nicht gesendet, bitte versuchen Sie es später erneut',
                    }
                };
export default new VueI18n({    
            locale: 'en',  //set locale 
            fallbackLocale: 'es',  //set fallback locale    
            messages,  //set locale messages
        });
